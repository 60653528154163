import { Link } from 'react-router-dom'

const GetStarted = () => {
	return (
		<>
			<section className="login-wr clearfix">
				<div className="login-side-blk left">
					<div className="login-side-logo">
						<figure>
							<Link to="/">
								<img className="img-generic" src="assets/images/Login_Page_Logo.svg" alt="Email Signature Logo" />
							</Link>
						</figure>
					</div>
					<div className="e-sign-comn-box e-sign-first-box">
						<Link  data-tagname="anchor" to="/signature-templates">
							<div className="banner-img-icon">
								<img className="img-generic" src="assets/images/35000_Template_Icon.svg" alt="35000 Template Icon" />
							</div>
							<div className="banner-img-text">
								<h5>35000 + Templates</h5>
								<p className='para'>Check Now</p>
							</div>
						</Link>
					</div>
					<div className="e-sign-comn-box e-sign-second-box banner-img-second">
						<a data-tagname="anchor" href="#">
							<div className="banner-img-icon">
								<img className="img-generic" src="assets/images/Highly_Rated_Icon.svg" alt="Highly Rated Icon" />
							</div>
							<div className="banner-img-text">
								<h5>Highly Rated</h5>
								<img className="img-generic" src="assets/images/Review_Star.svg" alt="Review Star" />
							</div>
						</a>
					</div>
				</div>
				<div className="login-form-blk right">
					<div className="center-wr">
						<div className="login-form-con">
							<div className="login-heading">
								<h3>Let's Get Started</h3>
							</div>
							<div className="login-form">
								<form method="post" action="">
									<div className="login-form-username form-field">
										<label className="fieldset">Your Name</label>
										<input type="text" name="name" placeholder="eg Aman Singh" />
									</div>
									<div className="login-form-username form-field">
										<label className="fieldset">Email</label>
										<input type="text" name="email" placeholder="enter your email" />
									</div>
									<div className="login-form-password form-field">
										<label className="fieldset">Phone</label>
										<input type="text" name="phone" placeholder="enter your phone number" />
									</div>
									<div className="login-form-password form-field">
										<label className="fieldset">Password</label>
										<input type="password" name="password" placeholder="Enter your Password" />
									</div>
									<div className="login-form-submit-btn">
										<input type="submit" name="submit-btn" value="Let's Go" />
									</div>
								</form>
								<div className="seperate-or">
									<p className='para'>Or</p>
								</div>
								<div className="login-with-google">
									<a data-tagname="anchor" href="#">Continue with Google</a>
								</div>
								<div className="new-user-register">
									<p className='para'>Already a User? <a data-tagname="anchor" href="#">Login Here</a></p>
								</div>
							</div>
						</div>
					</div>
					<div className="banner-outer-box">
						<a data-tagname="anchor" href="#">
							<div className="banner-img-icon e-sign-comn-box">
								<img className="img-generic" src="assets/images/login-outer-box-icon.svg" alt="150 Business Icon" />
							</div>
							<div className="banner-img-text">
								<h5>150+ Business</h5>
								<p className='para'>Check Categories</p>
							</div>
						</a>
					</div>
				</div>
			</section>
		</>
	)
}

export default GetStarted