import React from 'react'
import { Link } from 'react-router-dom';
import "./Footer.css"
import { FcLikePlaceholder } from 'react-icons/fc';


const Footer = ({loggerData}) => {
	const handleScrollTop = () => {
		window.scrollTo({
			top: 0, 
			behavior: 'smooth'
		});
	}
  return (
	<footer class="common-footer-wrapper">
		<div class="for-clippath"></div>
		<div class="center-wrapper center-wr">
			
			<div class="common-footer-container">
				<figure class="footer-heading">
					<Link to="/">
						<img className="img-generic" src={loggerData?.layout_data?.logo ? loggerData?.layout_data?.logo : "/assets/images/Email_Sign_Logo.svg"} alt="Email Signature Logo"/>
					</Link>
				</figure>
			<div class="footer-container-for">
			<div class="footer-info-container">
					<h4 style={{display: (!loggerData?.layout_data?.social_links?.instagram && !loggerData?.layout_data?.social_links?.youtube && !loggerData?.layout_data?.social_links?.linkedIn && !loggerData?.layout_data?.social_links?.facebook) ?  'none':'block'}}>Follow Us</h4>
					<div class="footer-icons-container" style={{display: (!loggerData?.layout_data?.social_links?.instagram && !loggerData?.layout_data?.social_links?.youtube && !loggerData?.layout_data?.social_links?.linkedIn && !loggerData?.layout_data?.social_links?.facebook) ?  'none':'flex'}}>
						<a style={{display: loggerData?.layout_data?.social_links?.instagram ? 'inline':"none"}} data-tagname="anchor" href={loggerData?.layout_data?.social_links ? loggerData?.layout_data?.social_links?.instagram : "#"}><img src="/assets/images/insta.svg" alt="footer icons insta"/></a>
						<a style={{display: loggerData?.layout_data?.social_links?.youtube ? 'inline':"none"}} data-tagname="anchor" href={loggerData?.layout_data?.social_links ? loggerData?.layout_data?.social_links?.youtube : "#"}><img src="/assets/images/youtube.svg" alt="footer icons youtube"/></a>
						<a style={{display: loggerData?.layout_data?.social_links?.linkedIn ? 'inline':"none"}} data-tagname="anchor" href={loggerData?.layout_data?.social_links ? loggerData?.layout_data?.social_links?.linkedIn : "#"}><img src="/assets/images/linkedin.svg" alt="footer icons linkedin"/></a>
						<a style={{display: loggerData?.layout_data?.social_links?.facebook ? 'inline':"none"}} data-tagname="anchor" href={loggerData?.layout_data?.social_links ? loggerData?.layout_data?.social_links?.facebook : "#"}><img src="/assets/images/fb.svg" alt="footer icons facebook"/></a>
					</div>
					<h4>Drop us a line</h4>
					<a className='footer-gml' data-tagname="anchor" href={loggerData?.layout_data?.email ? 'mailto:'+loggerData?.layout_data?.email :"mailto: info@esignature.com"}>info@emailsignature.com</a>
			</div>
			<div class="footer-links">
				<ul>
					<li><Link to="/about-us">About Us</Link></li>
					<li><Link to="/signature-templates">View Templates</Link></li>
				</ul>
				<ul>
					<li><Link to ="/sitemap">Sitemap</Link></li>
					<li><Link to ="/privacy-policy">Privacy Policy</Link></li>
					<li><Link to ="/terms-of-service">Terms & Conditions</Link></li>
				</ul>
				<ul>
					<li><Link to="/gmail-signature-generator">Gmail Signature</Link></li>
					<li><Link to="/outlook-signature-generator">Outlook Signature</Link></li>
					<li><Link to="/apple-signature-generator">Apple Mail Signature</Link></li>
				</ul>
			</div>
			</div>
		

			<div className="copyright-container">
				<h5 style={{fontWeight:'400', fontSize:'11px'}}>Copyright {new Date().getFullYear()} <Link style={{fontWeight:"400", fontSize:"11px"}} to="/">Email Signature</Link>. All Rights Reserved.</h5>
			</div>
			<div style={{display:"flex", alignItems:"center", justifyContent:"center", padding:"5px", gap:"5px"}}>
				<h5 style={{display:"block", fontWeight:"500", color:"#a5a5a5", fontSize:'14px',letterSpacing:"0.45px", fontFamily:"Sofia"}}>Crafted with</h5>
				<FcLikePlaceholder />
				<h5 style={{display:"block", fontWeight:"500", color:"#a5a5a5", fontSize:'14px',letterSpacing:"0.45px", fontFamily:"Sofia"}}> by <span style={{fontFamily:"Sora", fontSize:"12px"}}><a id='footer-creator-link' href='https://www.conativeitsolutions.com' style={{textDecoration:"none"}}>Conative IT Solutions</a></span></h5>
			</div>
			</div>
		</div>
	</footer>
	)
	}

export default Footer;