import {Link} from 'react-router-dom'

const ErrorHandler = () => {
  return (
    <div className='browse-err-cont'>
      <div className='browse-err'>
      <h2>Oops! It seems there's a hiccup in fetching our awesome templates</h2>
        <h3>Don't worry, our team is on it! Please try again in a moment or explore other parts of the site</h3>
      </div>
        <Link to='/' className='site-btn'> Back To Home </Link>
    </div>
  )
}

export default ErrorHandler